import HTMLReactParser from "html-react-parser";
import { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { fetchContent } from "../../redux/content/actions";

function Content({ id, title, hasWrap = true, content, fetchContent }) {
	useEffect(() => {
		if (_.isEmpty(content?.[id])) fetchContent(id);
	});

	const body = () => {
		return (
			<>
				{title !== undefined && <h2>{title}</h2>}
				<div>{HTMLReactParser(content?.[id] || "")}</div>
			</>
		);
	};

	return hasWrap ? <section className="content">{body()}</section> : body();
}

function mapStateToProps(state) {
	return {
		content: state.content
	};
}

const mapDispatchToProps = {
	fetchContent
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
