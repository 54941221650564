import { call, put } from "redux-saga/effects";

import { requestLogin, requestStatus } from "../requests/user";
import { statusUser } from "../../redux";

export function* handleUserLogin(args) {
	const { password } = args;

	try {
		const response = password ? yield call(requestLogin, password) : yield call(requestStatus);
		yield put(statusUser(response.data.status));
	} catch (error) {
		console.log(error);
		yield put(statusUser(error.response.status === 401 ? "wrong" : "logout"));
	}
}
